.animated-text {
    font-size: 28px;
    font-weight: 600;
    min-width: 280px;
}

.animated-text span {
    position: relative;
}

.animated-text span::before {
    content: "";
    color: #ff914d;
    animation: words 20s infinite;
}

.animated-text span::after {
    content: "";
    position: absolute;
    width: calc(100% + 10px);
    height: 45px;
    background-color: black;
    border-left: 2px solid #ff914d;
    right: -8px;
    animation: cursor .8s infinite, typing 20s steps(14) infinite;
}
@keyframes cursor {
    to {
        border-left: 2px solid #ff7f5000;
    }
}

@keyframes words {
    0%,20%{
        content: "Software Engineer";
    }
    21%,40%{
        content: "Cloud Engineer";
    }
    41%,60%{
        content: "Software Engineer";
    }
    61%,80%{
        content: "Cloud Engineer";
    }
    81%,100%{
        content: "Software Engineer";
    }
}
@keyframes typing {

    10%,
    15%,
    30%,
    35%,
    50%,
    55%,
    70%,
    75%,
    90%,
    95% {
        width: 0;
    }

    5%,
    20%,
    25%,
    40%,
    45%,
    60%,
    65%,
    80%,
    85% {
        width: calc(100% + 8px);
    }
}